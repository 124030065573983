import React from "react";
import styled from "styled-components";
import Image from "components/sections/Image";
import ImageText from "components/sections/ImageText";
import Rte from "components/sections/Rte";
import { FormattedDate } from "react-intl";
import { FieldLocale, getLocalizedField } from "utils/common/locale";
import { graphql } from "gatsby";
import SEO from "components/SEO";
import { DatoCmsProduct, ShopifyProduct } from "graphql-typings";
import ProductSlider from "components/sections/ProductSlider";

interface GalleryImage {
  alt?: string;
  url: string;
}

export interface ImageTextRecord {
  __typename: "DatoCmsImageText";
  textNode: { childMarkdownRemark: { html: string } };
  images: GalleryImage[];
  imageAlignment: "left" | "right";
}

export interface RteRecord {
  __typename: "DatoCmsRte";
  textNode: { childMarkdownRemark: { html: string } };
  textAlignment: "left" | "center" | "right";
}

export interface ImageRecord {
  __typename: "DatoCmsImage";
  images: GalleryImage[];
  backgroundAlignment: "left" | "right";
}

export interface ProductSliderRecord {
  __typename: "DatoCmsProductSlider";
  title: string;
  products: DatoCmsProduct[];
  link: any;
  linkLabel: string;
}

type ModularContent =
  | RteRecord
  | ImageRecord
  | ImageTextRecord
  | ProductSliderRecord;

interface Props {
  data: {
    datoCmsNews: {
      date: string;
      featuredImage: any;
      _allHandleLocales: FieldLocale<string>[];
      _allBodyLocales: FieldLocale<ModularContent[]>[];
      _allTitleLocales: any[];
      seo: any;
      excerpt: string;
    };
    allShopifyProduct: { nodes: ShopifyProduct[] };
  };
  pageContext: {
    locale: string;
    defaultLocale: string;
    allPathLocales: any[];
    pagePath: string;
    featuredProductIds: string[];
  };
}

const ArticleTemplate = ({ data, pageContext }: Props) => {
  const { locale, pagePath } = pageContext;
  const {
    datoCmsNews: news,
    allShopifyProduct: { nodes: shopifyProducts },
  } = data;

  const seo = news.seo;
  const title = news._allTitleLocales.find(
    (title) => title.locale === locale
  ).value;

  /**
   * Render modular content in page
   */
  const renderSection = (section: ModularContent, index: number) => {
    switch (section.__typename) {
      case "DatoCmsProductSlider":
        return (
          <ProductSlider
            key={index}
            content={section}
            shopifyProducts={shopifyProducts}
          />
        );
      case "DatoCmsRte":
        return <Rte key={index} content={section} />;
      case "DatoCmsImageText":
        return <ImageText key={index} content={section} />;
      case "DatoCmsImage":
        return <Image key={index} content={section} />;
    }
  };

  return (
    <>
      <SEO
        title={(seo && seo.title) || title}
        titleSuffix={seo && seo.title ? "" : undefined}
        description={(seo && seo.description) || news.excerpt}
        imageUrl={news.featuredImage && news.featuredImage.url}
        link={[
          { rel: "canonical", href: "https://ruedesmille.com" + pagePath },
        ]}
      />
      <Header>
        <Title>{title}</Title>
        <Date>
          <FormattedDate
            day="2-digit"
            month="long"
            year="numeric"
            value={news.date}
          />
        </Date>
      </Header>

      {getLocalizedField(news._allBodyLocales, locale)!.value.map(
        (section, index) => {
          return renderSection(section, index);
        }
      )}
    </>
  );
};

export const query = graphql`
  query articleQuery($newsId: String, $featuredProductIds: [String]) {
    datoCmsNews(id: { eq: $newsId }) {
      id
      tags
      date
      featuredImage {
        url
      }
      _allTitleLocales {
        locale
        value
      }
      _allHandleLocales {
        locale
        value
      }
      seo {
        title
        description
        twitterCard
        image {
          url
        }
      }
      _allTagsLocales {
        locale
        value
      }
      _allBodyLocales {
        locale
        value {
          __typename
          ... on DatoCmsRte {
            textNode {
              childMarkdownRemark {
                html
              }
            }
            textAlignment
          }
          ... on DatoCmsImage {
            images {
              alt
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
          ... on DatoCmsImageText {
            textNode {
              childMarkdownRemark {
                html
              }
            }
            images {
              alt
              gatsbyImageData(layout: CONSTRAINED)
            }
            imageAlignment
          }
          ... on DatoCmsProductSlider {
            title
            products {
              shopifyId
              title
              handle
              category {
                handle
              }
            }
            link {
              __typename
              ... on DatoCmsCategory {
                handle
              }
              ... on DatoCmsCollection {
                handle
              }
            }
            linkLabel
          }
        }
      }
      excerpt
    }
    allShopifyProduct(
      sort: { fields: [handle], order: ASC }
      filter: { storefrontId: { in: $featuredProductIds } }
    ) {
      nodes {
        shopifyId
        storefrontId
        handle
        description
        title
        media {
          id
          shopifyId
          mediaContentType
          ... on ShopifyMediaImage {
            image {
              originalSrc
              altText
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
        variants {
          shopifyId
          storefrontId
          title
          compareAtPrice
          price
          media {
            id
            shopifyId
            mediaContentType
            ... on ShopifyMediaImage {
              image {
                originalSrc
                altText
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
          selectedOptions {
            name
            value
          }
        }
      }
    }
  }
`;

const Header = styled.div`
  width: 700px;
  margin: 0 auto;
  margin-top: 60px;
  text-align: center;
  @media (max-width: 740px) {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }
`;

const Title = styled.h1`
  font-size: 22px;
  color: ${({ theme }) => theme.colors.main};
  letter-spacing: 0.16em;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 600;
`;

const Date = styled.div`
  font-size: 14px;
  color: #706f6f;
  letter-spacing: 0.02em;
  padding-bottom: 15px;
`;

export default ArticleTemplate;
