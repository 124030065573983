import Wrapper from "components/Wrapper";
import React from "react";
import styled from "styled-components";
import { RteRecord } from "templates/page";

interface Props {
  content: RteRecord;
}

const Rte: React.SFC<Props> = ({ content }) => (
  <Container textAlignment={content.textAlignment}>
    <Wrapper>
      <Content
        dangerouslySetInnerHTML={{
          __html: content.textNode.childMarkdownRemark.html
        }}
      />
    </Wrapper>
  </Container>
);

interface ContainerProps {
  textAlignment: "left" | "center" | "right";
}

const Container = styled.div`
  margin: 60px 0;
  display: flex;
  flex-direction: row;
  position: relative;
  text-align: ${(props: ContainerProps) =>
    ({
      left: "left",
      center: "center",
      right: "right"
    }[props.textAlignment || "left"])};
  ${Wrapper} {
    width: 700px;
    @media (max-width: 740px) {
      width: 100%;
    }
  }
`;

const Content = styled.div`
  color: #333;
  p {
    font-size: 14px;
    letter-spacing: 0.02em;
    line-height: 1.8em;
    margin: 0;
    margin-bottom: 15px;
    strong {
      font-weight: 600;
    }
  }
  a {
    color: ${({ theme }) => theme.colors.main};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  h1 {
    font-size: 22px;
    color: ${({ theme }) => theme.colors.main};
    letter-spacing: 0.16em;
    margin-bottom: 20px;

    text-transform: uppercase;
    font-weight: 600;
  }
  h2 {
    font-size: 20px;
    color: ${({ theme }) => theme.colors.main};
    letter-spacing: 0.16em;
    margin-bottom: 15px;
    margin-top: 15px;
    text-transform: uppercase;
    font-weight: 600;
  }
  h3 {
    font-size: 18px;
    color: ${({ theme }) => theme.colors.main};
    letter-spacing: 0.04em;
    margin-bottom: 15px;
    margin-top: 15px;
    font-weight: 600;
  }
  pre {
    white-space: normal;
  }
  img {
    width: 100%;
    display: block;
    margin: 20px 0;
  }
  ul,
  ol {
    li {
      font-size: 14px;
      letter-spacing: 0.02em;
      line-height: 1.8em;
      margin: 0;
      padding-bottom: 5px;
    }
  }
  blockquote {
    background-color: ${({ theme }) => theme.colors.background2};
    padding: 60px 40px;

    margin: 30px auto;
    box-sizing: border-box;
    position: relative;

    width: 100%;
    &:before,
    &:after {
      content: "“";
      display: block;
      position: absolute;
      color: ${({ theme }) => theme.colors.main};
      opacity: 0.3;
      font-size: 90px;
      font-weight: 400;
    }
    &:before {
      content: "“";
      top: -10px;
      left: 10px;
    }
    &:after {
      content: "”";
      bottom: -50px;
      right: 10px;
    }
    p {
      width: 100%;
      position: relative;
      color: ${({ theme }) => theme.colors.main};
      font-weight: 700;
      padding: 0;
    }
    ul {
      list-style: none;
      bottom: 0;
      right: 0;
      padding-top: 20px;
      text-align: right;
      li {
        color: ${({ theme }) => theme.colors.main};
        font-size: 14px;
        margin-bottom: -20px;
      }
    }
  }
`;

export default Rte;
