import { ShopifyProduct } from "graphql-typings";
import useComponentSize from "hooks/useComponentSize";
import { useLocale } from "hooks/useLocale";
import React, { useRef } from "react";
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from "react-carousel";
import styled from "styled-components";
import { ProductSliderRecord } from "templates/page";
import { ILocalizedProduct } from "types";
import { computePathFromLocalizedData } from "utils/common/navigation";
import ButtonLink from "components/ButtonLink";
import CircleArrowLeft from "components/icons/CircleArrowLeft";
import CircleArrowRight from "components/icons/CircleArrowRight";
import ProductCard from "components/ProductCard";
import Wrapper from "components/Wrapper";
import { universalBtoa } from "utils/product";

interface Props {
  content: ProductSliderRecord;
  shopifyProducts: ShopifyProduct[];
}

const ProductSlider: React.SFC<Props> = ({ content, shopifyProducts }) => {
  const { locale, defaultLocale } = useLocale();

  // Refs
  const ref = useRef(null);
  const size = useComponentSize(ref);

  const { title, products: datoCmsProducts, link, linkLabel } = content;

  const products = datoCmsProducts.map((datocmsProduct) => {
    const shopifyProduct = shopifyProducts.find(
      (product) =>
        universalBtoa(product.storefrontId) === datocmsProduct.shopifyId
    );

    return { dato: datocmsProduct, shopify: shopifyProduct };
  });

  const visibleSlides =
    size.width < 500 ? 1 : size.width < 740 ? 2 : size.width < 1000 ? 3 : 4;

  const linkPath =
    link &&
    computePathFromLocalizedData(
      locale,
      defaultLocale,
      link.__typename,
      link.handle
    );

  return (
    <Background>
      <Container>
        <Wrapper>
          <ProductSliderContainer ref={ref}>
            <Title>{title}</Title>
            <CarouselProvider
              totalSlides={products.length}
              visibleSlides={visibleSlides}
            >
              <Slider>
                {products.map((product, index) => (
                  <Slide key={index} index={index}>
                    <ProductCard product={product as ILocalizedProduct} />
                  </Slide>
                ))}
              </Slider>
              <ButtonBack>
                <CircleArrowLeft />
              </ButtonBack>
              <ButtonNext>
                <CircleArrowRight />
              </ButtonNext>
            </CarouselProvider>
            {link && (
              <LinkContainer>
                <ButtonLink to={linkPath}>{linkLabel}</ButtonLink>
              </LinkContainer>
            )}
          </ProductSliderContainer>
        </Wrapper>
      </Container>
    </Background>
  );
};

const Background = styled.div`
  background-color: ${({ theme }) => theme.colors.background2};
`;

const Container = styled.div`
  padding-top: 60px;
  padding-bottom: 60px;
  position: relative;

  @media (max-width: 1280px) {
    width: calc(100% - 60px);
    margin-left: 30px;
  }
`;

const ProductSliderContainer = styled.div`
  position: relative;
  button {
    background-color: transparent;
    height: 20px;
    width: 20px;
    border: none;
    transition: 0.3s all;
    padding: 5px;
    box-sizing: content-box;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    &[aria-label="previous"] {
      left: -30px;
    }
    &[aria-label="next"] {
      right: -30px;
    }
    :disabled {
      opacity: 0.3;
    }
    svg {
      stroke: ${({ theme }) => theme.colors.main};
      display: block;
      &:hover {
        stroke: #806724;
      }
    }
  }
`;

const Title = styled.div`
  font-size: 22px;
  color: ${({ theme }) => theme.colors.main};
  letter-spacing: 0.16em;
  margin-bottom: 40px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  @media (max-width: 860px) {
    font-size: 20px;
  }
  @media (max-width: 500px) {
    font-size: 18px;
  }
`;

const LinkContainer = styled.div`
  text-align: center;
`;

export default ProductSlider;
