import Wrapper from "components/Wrapper";
import React from "react";
import styled from "styled-components";
import { ImageRecord } from "templates/page";
import {
  CarouselProvider,
  Slider,
  Slide,
  DotGroup,
  ButtonNext,
  ButtonBack,
} from "react-carousel";
import CircleArrowLeft from "components/icons/CircleArrowLeft";
import CircleArrowRight from "components/icons/CircleArrowRight";
import { GatsbyImage } from "gatsby-plugin-image";

interface Props {
  content: ImageRecord;
}

const ImageComponent: React.SFC<Props> = ({ content }) => {
  if (content.images.length === 0) {
    return null;
  }
  if (content.images.length === 1) {
    return (
      <Container>
        <Wrapper>
          <ImageContainer>
            <BackgroundBox backgroundAlignment={content.backgroundAlignment} />
            <Image
              image={content.images[0].gatsbyImageData}
              alt={content.images[0].alt || ""}
            />
          </ImageContainer>
        </Wrapper>
      </Container>
    );
  }
  return (
    <Container>
      <Wrapper>
        <ImageContainer>
          <BackgroundBox backgroundAlignment={content.backgroundAlignment} />
          <CarouselProvider totalSlides={content.images.length}>
            <>
              <Slider>
                {content.images.map(
                  ({ gatsbyImageData, alt }, index: number) => (
                    <Slide key={index} index={index}>
                      <Image image={gatsbyImageData} alt={alt || ""} />
                    </Slide>
                  )
                )}
              </Slider>
              <ButtonBack>
                <CircleArrowLeft />
              </ButtonBack>
              <ButtonNext>
                <CircleArrowRight />
              </ButtonNext>
              <DotGroupStyled />
            </>
          </CarouselProvider>
        </ImageContainer>
      </Wrapper>
    </Container>
  );
};

interface ContainerProps {
  backgroundAlignment: "left" | "right";
}

const Container = styled.div`
  margin: 60px 0;
  display: flex;
  flex-direction: row;
  position: relative;
`;

const ImageContainer = styled.div`
  width: 100%;
  padding: 40px 0;
  display: block;
  position: relative;
  @media (max-width: 1300px) {
    padding: 40px 20px;
  }
  button {
    background-color: transparent;
    height: 20px;
    width: 20px;
    border: none;
    transition: 0.3s all;
    padding: 5px;
    box-sizing: content-box;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    &[aria-label="previous"] {
      left: -40px;
      @media (max-width: 1300px) {
        left: -35px;
      }
    }
    &[aria-label="next"] {
      right: -40px;
      @media (max-width: 1300px) {
        right: -35px;
      }
    }
    :disabled {
      opacity: 0.3;
    }
    svg {
      stroke: ${({ theme }) => theme.colors.main};
      display: block;
      &:hover {
        stroke: #806724;
      }
    }
  }
`;

const BackgroundBox = styled.div`
  position: absolute;
  z-index: 0;
  background-color: ${({ theme }) => theme.colors.background2};
  height: 100%;
  width: calc((100vw - 1200px) / 2 + 40%);
  top: 0px;
  right: ${(props: ContainerProps) =>
    props.backgroundAlignment === "left" ? "60%" : "auto"};
  left: ${(props: ContainerProps) =>
    props.backgroundAlignment === "left" ? "auto" : "60%"};
  @media (max-width: 1240px) {
    width: calc(40% + 20px);
    right: ${(props: ContainerProps) =>
      props.backgroundAlignment === "left" ? "auto" : "-20px"};
    left: ${(props: ContainerProps) =>
      props.backgroundAlignment === "left" ? "-20px" : "auto"};
  }
`;

const Image = styled(GatsbyImage)`
  display: block;
  width: 100%;
  position: relative;
  z-index: 1;
`;

const DotGroupStyled = styled(DotGroup)`
  display: flex;
  justify-content: center;
  background-color: transparent;
  position: absolute;
  bottom: 20px;
  width: 100%;

  button {
    position: relative;
    background-color: rgba(0, 0, 0, 0.2);
    height: 8px;
    width: 8px;
    padding: 0;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 50%;
    margin: 0 6px;
    &:disabled {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.6);
    }
    &:hover {
      cursor: pointer;
    }
  }
`;

export default ImageComponent;
