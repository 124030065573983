import CircleArrowLeft from "components/icons/CircleArrowLeft";
import CircleArrowRight from "components/icons/CircleArrowRight";
import Wrapper from "components/Wrapper";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from "react-carousel";
import styled from "styled-components";
import { ImageTextRecord } from "templates/page";

interface Props {
  content: ImageTextRecord;
}

const ImageText: React.SFC<Props> = ({ content }) => {
  const [selectedIndex, setSelectedIndex] = useState(
    content.images && content.images.length > 0 ? 0 : undefined
  );

  if (content.images.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      <Container imageAlignment={content.imageAlignment}>
        <ImageContainer imageAlignment={content.imageAlignment}>
          <BackgroundBox imageAlignment={content.imageAlignment} />
          {content.images.length === 1 ? (
            <Image
              image={content.images[0].gatsbyImageData}
              alt={content.images[0].alt || ""}
            />
          ) : (
            <>
              <div>
                <CarouselProvider
                  totalSlides={content.images.length}
                  currentSlide={selectedIndex}
                >
                  <>
                    <Slider onAfterDrag={(n) => setSelectedIndex(n)}>
                      {content.images.map(
                        ({ gatsbyImageData, alt }, index: number) => (
                          <Slide key={index} index={index}>
                            <Image image={gatsbyImageData} alt={alt || ""} />
                          </Slide>
                        )
                      )}
                    </Slider>
                  </>
                </CarouselProvider>
              </div>
              <ThumbnailsContainer>
                <CarouselProvider
                  totalSlides={content.images.length}
                  visibleSlides={4}
                  currentSlide={selectedIndex}
                >
                  <Slider onAfterDrag={(n) => setSelectedIndex(n)}>
                    {content.images &&
                      content.images.map((image: any, index: number) => (
                        <Slide
                          key={index}
                          index={index}
                          onClick={(n) => setSelectedIndex(n)}
                        >
                          <ThumbnailContainer>
                            <ImageThumbnail
                              image={image.gatsbyImageData}
                              alt={image.gatsbyImageData.alt || ""}
                            />

                            <ThumbnailFrame
                              selected={selectedIndex === index}
                            />
                          </ThumbnailContainer>
                        </Slide>
                      ))}
                  </Slider>
                  <ButtonBack onClick={(n: number) => setSelectedIndex(n)}>
                    <CircleArrowLeft />
                  </ButtonBack>
                  <ButtonNext onClick={(n: number) => setSelectedIndex(n)}>
                    <CircleArrowRight />
                  </ButtonNext>
                </CarouselProvider>
              </ThumbnailsContainer>
            </>
          )}
        </ImageContainer>
        <Content imageAlignment={content.imageAlignment}>
          {content.textNode && (
            <Text
              dangerouslySetInnerHTML={{
                __html: content.textNode.childMarkdownRemark.html,
              }}
            />
          )}
        </Content>
      </Container>
    </Wrapper>
  );
};

interface ContainerProps {
  imageAlignment: "left" | "right";
}

const Container = styled.div`
  margin: 80px 0;
  display: flex;
  flex-direction: ${(props: ContainerProps) =>
    props.imageAlignment === "left" ? "row" : "row-reverse"};
  position: relative;
  @media (max-width: 750px) {
    flex-direction: column-reverse;
  }
`;

const ImageContainer = styled.div`
  width: 50%;
  flex: 0 0 50%;
  position: relative;
  @media (max-width: 1280px) {
    padding: ${(props: ContainerProps) =>
      props.imageAlignment === "left" ? "0 0 0 20px" : "0 20px 0 0"};
  }
  @media (max-width: 750px) {
    width: 100%;
    flex: 0 0 100%;
    padding: 0 15%;
  }
  @media (max-width: 600px) {
    padding: 0 10%;
  }
  @media (max-width: 450px) {
    padding: 0;
  }
  button {
    background-color: transparent;
    height: 20px;
    width: 20px;
    border: none;
    transition: 0.3s all;
    padding: 5px;
    box-sizing: content-box;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    &[aria-label="previous"] {
      left: -30px;
    }
    &[aria-label="next"] {
      right: -30px;
    }
    :disabled {
      opacity: 0.3;
    }
    svg {
      stroke: ${({ theme }) => theme.colors.main};
      display: block;
      &:hover {
        stroke: #806724;
      }
    }
  }
`;

const BackgroundBox = styled.div`
  position: absolute;
  z-index: 0;
  background-color: ${({ theme }) => theme.colors.background2};
  height: calc(100% + 80px);
  width: calc((100vw - 1200px) / 2 + (100% - 100px));
  padding: 40px;
  top: -40px;
  right: ${(props: ContainerProps) =>
    props.imageAlignment === "left" ? "100px" : "auto"};
  left: ${(props: ContainerProps) =>
    props.imageAlignment === "left" ? "auto" : "100px"};
  @media (max-width: 1240px) {
    width: calc(100% - 100px + 20px);
  }
  @media (max-width: 750px) {
    width: calc(60% + 20px);
    right: ${(props: ContainerProps) =>
      props.imageAlignment === "left" ? "40%" : "auto"};
    left: ${(props: ContainerProps) =>
      props.imageAlignment === "left" ? "auto" : "40%"};
  }
`;
const Image = styled(GatsbyImage)`
  background-color: ${({ theme }) => theme.colors.grey};
  display: block;
  width: 100%;
  position: relative;
  z-index: 1;
`;

const Content = styled.div`
  width: 50%;
  flex: 0 0 50%;
  padding: ${(props: ContainerProps) =>
    props.imageAlignment === "left" ? "40px 0 40px 80px" : "40px 80px 40px 0"};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${(props: ContainerProps) =>
    props.imageAlignment === "left" ? "flex-end" : "flex-start"};
  text-align: ${(props: ContainerProps) =>
    props.imageAlignment === "left" ? "left" : "left"};
  @media (max-width: 1240px) {
    padding: ${(props: ContainerProps) =>
      props.imageAlignment === "left"
        ? "40px 40px 40px 60px"
        : "40px 60px 40px 40px"};
  }
  @media (max-width: 750px) {
    width: 100%;
    flex: 0 0 100%;
    padding: 0 40px 80px 40px;
    text-align: left;
  }
  @media (max-width: 450px) {
    padding: 0 20px 80px 20px;
  }
`;

const Text = styled.div`
  color: #333;
  width: 100%;
  p {
    font-size: 14px;
    letter-spacing: 0.02em;
    line-height: 1.8em;
    margin: 0;
    padding-bottom: 15px;
    strong {
      font-weight: 600;
    }
  }
  a {
    color: ${({ theme }) => theme.colors.main};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  h1 {
    font-size: 22px;
    color: ${({ theme }) => theme.colors.main};
    letter-spacing: 0.16em;
    text-transform: uppercase;
    font-weight: 600;
    margin: 0;
    &:last-of-type {
      margin-bottom: 20px;
    }
  }
  h2 {
    font-size: 20px;
    color: ${({ theme }) => theme.colors.main};
    letter-spacing: 0.16em;
    text-transform: uppercase;
    font-weight: 600;
    margin: 0;
    &:last-of-type {
      margin-bottom: 20px;
    }
  }
  pre {
    white-space: normal;
  }
  ul,
  ol {
    li {
      font-size: 14px;
      letter-spacing: 0.02em;
      line-height: 1.8em;
      margin: 0;
      padding-bottom: 5px;
    }
  }
`;

const ThumbnailsContainer = styled.div`
  width: 100%;
  position: relative;
  width: calc(100% + 10px);
  margin-top: 10px;
  margin-left: -5px;
  @media (max-width: 1280px) {
    width: calc(100% - 20px + 10px);
    margin-left: calc(20px - 5px);
  }
  @media (max-width: 1100px) {
    width: calc(100% - 60px + 10px);
    margin-left: calc(20px - 5px);
  }
  @media (max-width: 1000px) {
    width: calc(100% - 80px + 10px);
    margin-left: calc(40px - 5px);
  }
  button {
    background-color: transparent;
    height: 20px;
    width: 20px;
    border: none;
    transition: 0.3s all;
    padding: 5px;
    box-sizing: content-box;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    &[aria-label="previous"] {
      left: -30px;
    }
    &[aria-label="next"] {
      right: -30px;
    }
    :disabled {
      opacity: 0.3;
    }
    svg {
      stroke: ${({ theme }) => theme.colors.main};
      display: block;
      &:hover {
        stroke: #806724;
      }
    }
  }
`;

const ThumbnailContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  padding: 5px;
  &:focus {
    outline: none;
  }
`;
const ImageThumbnail = styled(GatsbyImage)`
  max-width: 100%;
  max-height: 100%;
  display: block;
  position: relative;
  height: auto;
  &:hover {
    cursor: pointer;
  }
`;
const ThumbnailFrame = styled.div`
  z-index: 1;
  height: calc(100% - 8px);
  left: 0;
  position: absolute;
  top: 0;
  display: block;
  width: calc(100% - 8px);
  pointer-events: none;
  transition: 0.3s all;
  box-shadow: inset 0px 0px 0px 4px transparent;
  margin: 4px;
  ${({ selected }: { selected: boolean }) => {
    if (selected) {
      return `box-shadow: inset 0px 0px 0px 4px #977B2B`;
    }
  }}
`;

export default ImageText;
